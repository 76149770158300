import React from "react";
import { useParams } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import ParserDetailsAppBarComponent from "../components/parser-config-details-view/parser-appbar";
import LayoutWithTabs from "../components/field-config/field-config-tabpanel";

const ParserFieldConfiguration: React.FC = () => {
  const { parserId } = useParams<{ parserId: string }>();
  // id is composed of supplier name and parser id separated by `:`
  const parts = parserId.split(":");
  const supplierName = parts[0];
  const _parserId = parts[1];

  return (
    <>
      <ParserDetailsAppBarComponent parserId={_parserId} />
      <Box>
        <Breadcrumbs aria-label="breadcrumb">
          <Link color="inherit" to="/">
            Home
          </Link>
          <Link color="inherit" to="/ai/parsers">
            Parsers
          </Link>

          <Typography color="textPrimary">{supplierName}</Typography>
        </Breadcrumbs>
        <LayoutWithTabs parserId={_parserId} />
      </Box>
    </>
  );
};

export default ParserFieldConfiguration;
