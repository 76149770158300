import { RootState } from "store";

// Suppliers
export const selectIsFetchingSuppliers = (state: RootState) =>
  state.aiParserNext.isFetchingSuppliers;

// Parser config creation in progress
export const selectIsCreatingConfiguration = (state: RootState) =>
  state.aiParserNext.isCreatingConfiguration;

// Parser config error
export const selectConfigurationError = (state: RootState) => state.aiParserNext.error;

// Selector to get suppliers from state
export const selectSuppliers = (state: RootState) => state.aiParserNext.suppliers;

// Samples
export const selectSamples = (state: RootState) => state.aiParserNext.samples;

export const selectSamplesLoading = (state: RootState) => state.aiParserNext.isSamplesLoading;

export const selectSamplesError = (state: RootState) => state.aiParserNext.error;

export const selectConfigurations = (state: RootState) => state.aiParserNext.configurations;
export const selectConfigurationsLoading = (state: RootState) =>
  state.aiParserNext.isConfigurationLoading;

// Selector to get the fields
export const selectFields = (state: RootState) => state.aiParserNext.fields;

// Selector to get the loading state
export const selectFieldsLoading = (state: RootState) => state.aiParserNext.isFieldsLoading;

// Selector to get the error state
export const selectFieldsError = (state: RootState) => state.aiParserNext.error;

export const selectRegexTestResult = (state: RootState) => state.aiParserNext.testResult;

export const selectLoading = (state: RootState) => state.aiParserNext.isRegexTestLoading;

export const selectSampleDataById = (state: RootState, sampleId: string) => {
  return state.aiParserNext.sampleResults[sampleId];
};

export const selectSampleLoading = (state: RootState, sampleId: string) => {
  return state.aiParserNext.loadingSampleResults[sampleId] || false;
};
// Selector to get error state
export const selectSampleError = (state: RootState, sampleId: string) =>
  state.aiParserNext.errorSampleResults[sampleId] || null;

export const selectSampleData = (state: RootState) => state.aiParserNext.sampleData;

export const selectSampleDataLoading = (state: RootState) => state.aiParserNext.isSampleDataLoading;

export const selectSampleDataError = (state: RootState) => state.aiParserNext.errorSampleData;

export const selectCurrentParserConfiguration = (state: RootState) =>
  state.aiParserNext.currentParserConfiguration;

export const selectSampleTestData = (state: RootState) => state.aiParserNext.sampleTestData;

export const selectSampleTestDataLoading = (state: RootState) =>
  state.aiParserNext.isSampleTestDataLoading;

export const selectSampleTestDataError = (state: RootState) =>
  state.aiParserNext.errorSampleTestData;

export const creatParserConfigSuccess = (state: RootState) =>
  state.aiParserNext.createConfigurationSuccess;

export const deleteParserSampleSuccess = (state: RootState) =>
  state.aiParserNext.deleteParserSampleSuccess;

export const deleteParserSampleError = (state: RootState) =>
  state.aiParserNext.deleteParserSampleSuccess;

export const deletePraserSampleLoading = (state: RootState) =>
  state.aiParserNext.deleteParserSampleRequest;
