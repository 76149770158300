import React, { useEffect } from "react";
import {
  Box,
  Breadcrumbs,
  CircularProgress,
  Paper,
  Typography
} from "@mui/material";
import { useParams } from "react-router-dom";
import FieldsExtractionGrid from "../components/parser-sample-setup/field-extraction-grid";
import SampleResultAccordion from "../components/parser-sample-setup/sample-result-accordion";
import ToolbarSampleActions from "../components/parser-sample-setup/sample-action-toolbar";
import { useDispatch, useSelector } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";

import {
  selectSampleData,
  selectSampleDataError,
  selectSampleDataLoading
} from "@next/modules/ai/redux/selectors";

import { Link } from "react-router-dom";
import RFQSampleViewer from "../components/rfq-sample-viewer/rfq-sample-viewer";
const ParserConfigurationSetup: React.FC = () => {
  const { parserId, sampleId } = useParams<{
    parserId: string;
    sampleId: string;
  }>();

  const parts = parserId.split(":");
  const supplierName = parts[0];
  const _parserId = parts[1];

  const dispatch = useDispatch();

  const sampleData = useSelector(selectSampleData) as SampleData;
  const loading = useSelector(selectSampleDataLoading);
  const error = useSelector(selectSampleDataError);

  useEffect(() => {
    dispatch(
      parserActions.fetchSampleDataRequest({ parserId: _parserId, sampleId })
    );
  }, [dispatch, _parserId, sampleId]);

  if (loading) {
    return <div>Loading...</div>;
  }

  if (error) {
    return <div>{error}</div>;
  }
  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">{error}</Typography>
      </Box>
    );
  }

  return (
    <Paper elevation={3}>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ paddingTop: 5, paddingLeft: 5 }}
      >
        <Link to="/">Home</Link>
        <Link to="/ai/parsers">Parsers</Link>
        <Link to={`/ai/parsers/${parserId}`}>{supplierName}</Link>
        <Typography color="textPrimary">Sample - {sampleData?.id}</Typography>
      </Breadcrumbs>
      <Box mt={3}>
        <ToolbarSampleActions
          parserId={_parserId}
          sampleId={sampleData?.id}
          isReady={sampleData?.is_ready}
          onDeleteSample={() => console.log("Sample deleted")}
          onMarkSampleReady={() => console.log("Sample marked as ready")}
        />
        {sampleData && (
          <FieldsExtractionGrid parserId={_parserId} sampleData={sampleData} />
        )}

        <SampleResultAccordion parserId={_parserId} sampleId={sampleId} />
        <RFQSampleViewer
          parserId={_parserId}
          sampleId={sampleId}
          sampleData={sampleData}
        />
      </Box>
    </Paper>
  );
};

export default ParserConfigurationSetup;
