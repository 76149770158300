import { Box, Theme } from "@mui/material";
import React from "react";
import { OrderDetailDraftHeader } from "./order-detail-draft-header";
import { SendOrderDrawer } from "@next/modules/orders/modals/send-order-drawer/send-order-drawer";
import { useTranslation } from "react-i18next";
import { createStyles, makeStyles } from "@mui/styles";
import { OrderDetailDraftLineItemsTable } from "./order-detail-draft-line-items-table";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      width: "100%"
    },
    paper: {
      padding: "24px",
      boxShadow: "none",
      border: `1px solid ${theme.palette.divider}`,
      marginTop: "24px"
    }
  })
);

export const OrderDetailDraft = () => {
  const { t } = useTranslation();
  const classes = useStyles();

  return (
    <Box className={classes.root}>
      <OrderDetailDraftHeader />

      <Box width="100%">
        <OrderDetailDraftLineItemsTable />
      </Box>

      <SendOrderDrawer />
    </Box>
  );
};
