import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  Box,
  Chip,
  CircularProgress,
  Grid,
  Tab,
  Tabs,
  Typography
} from "@mui/material";

import {
  selectSamples,
  selectSamplesError,
  selectSamplesLoading
} from "@next/modules/ai/redux/selectors";
import { parserActions } from "@next/modules/ai/redux/slices";
import HighlightableTextField from "./highlightable-text-field";

export default function SampleTabs({ parserId }) {
  const dispatch = useDispatch();
  const samples = useSelector(selectSamples);
  const isLoading = useSelector(selectSamplesLoading);
  const error = useSelector(selectSamplesError);

  const [value, setValue] = React.useState(0);

  useEffect(() => {
    dispatch(parserActions.fetchSamplesRequest(parserId)); // Adjust action accordingly
  }, [dispatch]);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (isLoading) return <CircularProgress />;
  if (error) return <Typography color="error">{error}</Typography>;
  if (!samples.length) return <Typography>No samples available</Typography>;

  return (
    <Box sx={{ width: "100%" }}>
      <Tabs value={value} onChange={handleChange} aria-label="sample data tabs">
        {samples.map((sample, index) => (
          <Tab label={`Sample ${sample.id}`} key={sample.id} />
        ))}
      </Tabs>
      {samples.map((sample, index) => (
        <TabPanel key={sample.id} value={value} index={index} sample={sample} />
      ))}
    </Box>
  );
}

function TabPanel(props) {
  const { children, value, index, sample, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Grid container>
            <Grid item xs={6}>
              <FieldDetails fieldData={sample.extracted_fields} />
            </Grid>
          </Grid>
        </Box>
      )}
    </div>
  );
}

function FieldDetails({ fieldData }: { fieldData: Field }) {
  const [highlights, setHighlights] = useState([
    "64,17",
    "CC-GD-15.8x6.6-M1-1-1 AC-HRPO-10G Découpe"
  ]);

  const handleAddHighlight = (keyword) => {
    if (!highlights.includes(keyword)) {
      setHighlights([...highlights, keyword]);
    }
  };

  const handleRemoveHighlight = (keyword) => {
    setHighlights(highlights.filter((highlight) => highlight !== keyword));
  };

  return (
    <Box>
      {Object.entries(fieldData).map(([key, value], index) =>
        value ? (
          <Box>
            <HighlightableTextField
              text={value?.selectedText}
              highlights={highlights}
              onAddHighlight={handleAddHighlight}
              onRemoveHighlight={handleRemoveHighlight}
            />

            {/* Example Chip to add highlights manually */}
            <Box sx={{ mt: 2 }}>
              <Chip
                label="Add 'highlight'"
                onClick={() => handleAddHighlight("32,17")}
                variant="outlined"
                color="secondary"
              />
            </Box>
          </Box>
        ) : null
      )}
    </Box>
  );
}
