import { GridCellParams } from "@mui/x-data-grid-pro-v5";
import {
  PartialStep,
  Questionnaire,
  QuestionnaireType,
  SpecOfSubtier,
  SubtierTableRow,
  UpdateSpecOfSubtierSupplier,
  vendorManagementActions,
} from "@next/modules/vendor-management/redux";
import {
  selectIsFormDisplayForValidationManagement,
  selectIsFormStepCompleted,
  selectQuestionnaireName,
  selectSpecsOfSubtierSupplier,
  selectVisibleSteps,
} from "@next/modules/vendor-management/redux/selectors";
import { getActiveStepIndex } from "@next/modules/vendor-management/utils/getActiveStepIndex";
import { formatDate } from "helpers/formatDate";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import { SelectDateMenu } from "../table-menu/select-date-menu/select-date-menu";
import { SelectOptionMenu } from "../table-menu/select-option-menu/select-option-menu";
import {
  getSubtierReadinessFormTableColumns,
  selectComplianceMenuItems,
} from "./subtier-readiness-form-table.columns";
import * as S from "./subtier-readiness-form-table.styled";

type Props = {
  subtierSupplierId: string;
  isTableDisabled: boolean;
  subtierTitle;
};

export const SubtierReadinessFormTable: React.FC<Props> = ({
  subtierSupplierId,
  isTableDisabled,
  subtierTitle,
}) => {
  const { companyId } = useParams<{ companyId: string }>();
  const dispatch = useDispatch();
  const questionnaireName: Questionnaire = useSelector(selectQuestionnaireName);
  const isFormCompleted = useSelector(selectIsFormStepCompleted);
  const isFormDisplayForValidationManagement = useSelector(
    selectIsFormDisplayForValidationManagement
  );
  const steps = useSelector(selectVisibleSteps);
  const activeFormStepIndex = getActiveStepIndex(steps);
  const step = steps[activeFormStepIndex];
  const columns = getSubtierReadinessFormTableColumns(
    isFormDisplayForValidationManagement,
    isTableDisabled,
    subtierTitle
  );
  const rows = useSelector(selectSpecsOfSubtierSupplier(subtierSupplierId));
  const [anchorElForDateMenu, setAnchorElForDateMenu] = useState<null | HTMLElement>(null);
  const [anchorElForOptionMenu, setAnchorElForOptionMenu] = useState<null | HTMLElement>(null);
  const [row, setRow] = useState<SubtierTableRow>(null);
  const [field, setField] = useState<string>("");
  const [currentSelectionDate, setCurrentSelectionDate] = useState<string>();
  const [specOfScopeData, setSpecOfScopeData] = useState<UpdateSpecOfSubtierSupplier>();

  const handleCellClick = (params: GridCellParams, event: React.MouseEvent<HTMLElement>) => {
    if (params.field === "poe" && params.row.compliance && !isTableDisabled) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForDateMenu(event.currentTarget);
    } else if (params.field === "compliance" && !isTableDisabled) {
      setRow(params.row);
      setField(params.field);
      setAnchorElForOptionMenu(event.currentTarget);
    } else if (params.field === "removeRowButton" && !isTableDisabled) {
      const id = params.row.id;
      dispatch(
        vendorManagementActions.deleteSpecOfSubtierSupplierRequest({
          companyId: companyId,
          supplierId: subtierSupplierId,
          specificationId: id,
          onSuccess: () => onRemoveSuccess(params.row?.entity_id),
        })
      );
      return;
    }
  };

  const onRemoveSuccess = (entity_id: number) => {
    checkInputChange();
    const step = steps[activeFormStepIndex];
    dispatch(
      vendorManagementActions.decreaseUnapprovedThreadsCountOfStepIfRemovedLineHasUnresolvedThread({
        stepId: step.step,
        entityIdOfLine: entity_id,
      })
    );
    reFetchStepsToGetUpdatedUnresolvedThreadsCountsOfSteps();
  };

  const reFetchStepsToGetUpdatedUnresolvedThreadsCountsOfSteps = () => {
    dispatch(
      vendorManagementActions.fetchStepsRequestWithoutCausingReRendering({
        companyId: companyId,
        questionnaireType: QuestionnaireType[questionnaireName],
      })
    );
  };

  const checkInputChange = () => {
    if (isFormCompleted) {
      updateFieldStatusOfStepForSaveAndCompletion("is_completed", false);
    } else {
      updateFieldStatusOfStepForSaveAndCompletion("is_partially_filled", true);
    }
  };

  const updateFieldStatusOfStepForSaveAndCompletion = (
    key: "is_completed" | "is_partially_filled",
    value: boolean
  ) => {
    const step = steps[activeFormStepIndex];
    const partialStep: PartialStep = {
      [key]: value,
    };
    dispatch(
      vendorManagementActions.updateStepRequest({
        stepId: step.id,
        data: partialStep,
        onSuccess:
          key === "is_completed" && !value
            ? () => updateApprovalOfStepAfterCompletionChange("changes_requested")
            : undefined,
      })
    );
  };

  const updateApprovalOfStepAfterCompletionChange = (statusParam: "changes_requested") => {
    if (step?.approval_status === "changes_done" && statusParam === "changes_requested") {
      dispatch(
        vendorManagementActions.updateApprovalStatusOfStepRequest({
          companyId: companyId,
          stepId: step.step,
          status: statusParam,
        })
      );
    }
  };

  const handleClickComplianceOption = (menuItem: string) => {
    const supplierId = subtierSupplierId;
    const specification_id = row.specification_id;

    const spec: SpecOfSubtier = { compliance: menuItem };

    dispatch(
      vendorManagementActions.updateSpecOfSubtierSupplierRequest({
        spec: spec,
        companyId: companyId,
        supplierId: supplierId,
        specificationId: specification_id,
      })
    );
    checkInputChange();
    setAnchorElForOptionMenu(null);
  };

  const handleClickDateSelection = (date: Date | null) => {
    const formattedDate = formatDate(date, "YYYY-MM-DD");
    setCurrentSelectionDate(formattedDate);

    const updatedSpec: UpdateSpecOfSubtierSupplier = {
      spec: { poe: formattedDate },
      companyId: companyId,
      supplierId: subtierSupplierId,
      specificationId: row.specification_id,
    };

    setSpecOfScopeData(updatedSpec);

    if (formattedDate !== row?.poe) {
      dispatch(
        vendorManagementActions.updateSpecOfSubtierSupplierRequest({
          ...updatedSpec,
        })
      );
    }
  };

  const handleOnClose = () => {
    if (currentSelectionDate !== row?.poe) {
      dispatch(
        vendorManagementActions.updateSpecOfSubtierSupplierRequest({
          ...specOfScopeData,
        })
      );
    }
    checkInputChange();
    setAnchorElForDateMenu(null);
  };

  return (
    <>
      <S.StyledDataGridPro
        columns={columns}
        rows={rows || []}
        autoHeight
        autoPageSize
        hideFooter
        headerHeight={48}
        rowHeight={!isFormDisplayForValidationManagement ? 32 : 40}
        disableSelectionOnClick
        onCellClick={handleCellClick}
        getRowId={(row) => row.specification_id || uuidv4()}
        getRowClassName={(params) => {
          const isDisabledStatus = params?.row?.approval_status === "approved";
          return isDisabledStatus ? "disabled-row" : "";
        }}
      />

      {!isFormDisplayForValidationManagement ? (
        <>
          <SelectOptionMenu
            anchorEl={anchorElForOptionMenu}
            setAnchorEl={setAnchorElForOptionMenu}
            handleClick={handleClickComplianceOption}
            row={row}
            field={field}
            menuItems={selectComplianceMenuItems}
          />

          <SelectDateMenu
            anchorEl={anchorElForDateMenu}
            row={row}
            currentSelectionDate={currentSelectionDate}
            setCurrentSelectionDate={setCurrentSelectionDate}
            handleChange={handleClickDateSelection}
            handleOnClose={handleOnClose}
          />
        </>
      ) : null}
    </>
  );
};
