import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import {
  Configuration,
  ParserState,
  RegexPayload,
  Sample,
  SampleExecutionResult,
  Supplier,
  TestRegexResult,
  UploadSamplePayload,
} from "./types";

const initialState: ParserState = {
  suppliers: [],
  configurations: [],
  samples: [],
  fields: [],
  testResult: [],
  isSamplesLoading: false,
  isConfigurationLoading: false,
  currentParserConfiguration: {},
  isFieldsLoading: false,
  isRegexTestLoading: false,
  isFetchingSuppliers: false,
  isCreatingConfiguration: false,
  isUploadSample: false,
  isDeleteSampleLoading: false,
  isMarkingReady: false,
  isSampleDataLoading: false,
  markReadyError: null,
  error: null,
  sampleResults: {},
  loadingSampleResults: {},
  errorSampleResults: {},
  sampleData: null,
  errorSampleData: null,
  sampleTestData: {},
  isSampleTestDataLoading: false,
  errorSampleTestData: null,
};

const parserSlice = createSlice({
  name: "parser",
  initialState,
  reducers: {
    // Fetch suppliers actions
    fetchSuppliersRequest(state) {
      state.isFetchingSuppliers = true;
      state.error = null;
    },
    fetchSuppliersSuccess(state, action: PayloadAction<Supplier[]>) {
      state.suppliers = action.payload;
      state.isFetchingSuppliers = false;
    },
    fetchSuppliersFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFetchingSuppliers = false;
    },

    fetchConfigurationsRequest(state) {
      state.isConfigurationLoading = true;
      state.error = null;
    },
    fetchConfigurationsSuccess(state, action: PayloadAction<Configuration[]>) {
      state.configurations = action.payload;
      state.isConfigurationLoading = false;
    },
    fetchConfigurationsFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isConfigurationLoading = false;
    },
    // Create configuration actions
    createConfigurationRequest(state, action: PayloadAction<FormData>) {
      state.isCreatingConfiguration = true;
      state.error = null;
    },
    createConfigurationSuccess(state, action: PayloadAction<FormData>) {
      state.isCreatingConfiguration = false;
    },
    createConfigurationFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isCreatingConfiguration = false;
    },

    // Fetch samples actions
    fetchSamplesRequest(state, action: PayloadAction<string>) {
      state.isSamplesLoading = true;
      state.error = null;
    },
    fetchSamplesSuccess(state, action: PayloadAction<Sample[]>) {
      state.samples = action.payload;
      state.isSamplesLoading = false;
    },
    fetchSamplesFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isSamplesLoading = false;
    },

    // Upload Sample
    uploadSampleRequest(state, action: PayloadAction<UploadSamplePayload>) {
      state.isUploadSample = true;
      state.error = null;
    },
    uploadSampleSuccess(state) {
      state.isUploadSample = false;
    },
    uploadSampleFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isUploadSample = false;
    },

    // Delete Sample
    deleteParserSampleRequest(
      state,
      action: PayloadAction<{ parserId: string; sampleId: number }>
    ) {
      state.isDeleteSampleLoading = true;
      state.error = null;
    },
    deleteParserSampleSuccess(state) {
      state.isDeleteSampleLoading = false;
    },
    deleteParserSampleError(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isDeleteSampleLoading = false;
    },

    fetchParserConfigurationRequest(
      state,
      action: PayloadAction<{ parserId: string; sampleData: SampleData }>
    ) {
      state.isFieldsLoading = true;
      state.error = null;
    },
    fetchParserConfigurationSuccess(
      state,
      action: PayloadAction<{ fields: Field[]; parserConfiguration: object }>
    ) {
      const { fields, parserConfiguration } = action.payload;
      state.fields = fields;
      state.currentParserConfiguration = parserConfiguration;
      state.isFieldsLoading = false;
    },
    fetchParserConfigurationFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isFieldsLoading = false;
    },

    // Update Sample Data
    updateSampleField(
      state,
      action: PayloadAction<{
        parserId: string;
        sampleId: number;
        fieldId: number;
        fieldKey: string;
        data: Partial<Field>;
      }>
    ) {
      const { fieldKey, data } = action.payload;
      const field = state.fields.find((f) => f.key === fieldKey);
      if (field) {
        Object.assign(field, data);
      }
    },

    updateParserConfigRequest(
      state,
      _action: PayloadAction<{
        parserId: string;
        data: object;
        action_type: "update" | "cancel" | "publish";
      }>
    ) {
      state.isSamplesLoading = true;
      state.error = null;
    },

    updateParserConfigSuccess(
      state,
      action: PayloadAction<{
        fieldId: string | null;
        regex: string | RegexConfiguration | null;
        configuration: Configuration;
      }>
    ) {
      const { fieldId, regex, configuration } = action.payload;
      if (fieldId && regex) {
        const field = state.fields.find((f) => f.key === fieldId);
        if (field) {
          field.regex = regex; // Update the regex for the field
        }
      }

      state.configurations = state.configurations.map((c) =>
        c.id === configuration.id ? configuration : c
      );
      state.currentParserConfiguration = configuration;
      state.isSamplesLoading = false;
    },

    updateParserConfigFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isSamplesLoading = false;
    },

    // Regex actions
    testRegexRequest(state, _action: PayloadAction<RegexPayload>) {
      state.isRegexTestLoading = true;
      state.error = null;
      state.testResult = null;
    },
    testRegexSuccess(state, action: PayloadAction<TestRegexResult>) {
      state.isRegexTestLoading = false;
      state.testResult = action.payload.result;
    },
    testRegexFailure(state, action: PayloadAction<string>) {
      state.error = action.payload;
      state.isRegexTestLoading = false;
    },

    markSampleReadyRequest(
      state,
      action: PayloadAction<{
        parserId: string;
        sampleId: number;
        flag: boolean;
      }>
    ) {
      state.isMarkingReady = true;
      state.markReadyError = null;
    },
    markSampleReadySuccess(state) {
      state.isMarkingReady = false;
    },
    markSampleReadyFailure(state, action: PayloadAction<string>) {
      state.isMarkingReady = false;
      state.markReadyError = action.payload;
    },
    fetchSampleResultRequest(state, action: PayloadAction<{ parserId: string; sampleId: string }>) {
      state.loadingSampleResults[action.payload.sampleId] = true; // Set loading to true when request starts
      state.errorSampleResults[action.payload.sampleId] = null; // Reset any previous error
    },
    fetchSampleResultSuccess(
      state,
      action: PayloadAction<{
        sampleId: string;
        sampleResult: SampleExecutionResult;
        failedSamples: number[];
        lastRunDate: string;
      }>
    ) {
      state.loadingSampleResults[action.payload.sampleId] = false;
      state.sampleResults[action.payload.sampleId] = {
        sampleResult: action.payload.sampleResult,
        failedSamples: action.payload.failedSamples,
        lastRunDate: action.payload.lastRunDate,
      };
      state.errorSampleResults[action.payload.sampleId] = null;
    },
    fetchSampleResultFailure(state, action: PayloadAction<{ sampleId: string; error: string }>) {
      state.loadingSampleResults[action.payload.sampleId] = false; // Set loading to false on failure
      state.errorSampleResults[action.payload.sampleId] = action.payload.error; // Store error message
    },

    fetchSampleDataRequest(state, action: PayloadAction<{ parserId: string; sampleId: string }>) {
      state.isSampleDataLoading = true;
      state.error = null;
    },
    fetchSampleDataSuccess(state, action: PayloadAction<SampleData>) {
      state.sampleData = action.payload;
      state.isSampleDataLoading = false;
      state.errorSampleData = null;
    },
    fetchSampleDataFailure(state, action: PayloadAction<string>) {
      state.isSampleDataLoading = false;
      state.errorSampleData = action.payload;
    },
    // Sample Test Data
    fetchSampleTestDataRequest(
      state,
      action: PayloadAction<{ parserId: string; sampleId: string }>
    ) {
      state.isSampleTestDataLoading = true;
      state.errorSampleTestData = null;
    },
    fetchSampleTestDataSuccess(state, action: PayloadAction<object>) {
      state.isSampleTestDataLoading = false;
      state.sampleTestData = action.payload;
    },
    fetchSampleTestDataFailure(state, action: PayloadAction<string>) {
      state.isSampleTestDataLoading = false;
      state.errorSampleTestData = action.payload;
    },

    // Add new actions for update and create sample test data
    updateSampleTestDataRequest(
      state,
      action: PayloadAction<{
        parserId: string;
        sampleId: string;
        payload: object;
      }>
    ) {
      state.isSampleTestDataLoading = true;
      state.errorSampleTestData = null;
    },
    updateSampleTestDataSuccess(state) {
      state.isSampleTestDataLoading = false;
      state.errorSampleTestData = null;
    },
    updateSampleTestDataFailure(state, action: PayloadAction<string>) {
      state.isSampleTestDataLoading = false;
      state.errorSampleTestData = action.payload;
    },

    createSampleTestDataRequest(
      state,
      action: PayloadAction<{ parserId: string; sampleId: string }>
    ) {
      state.isSampleTestDataLoading = true;
      state.errorSampleTestData = null;
    },
    createSampleTestDataSuccess(
      state,
      action: PayloadAction<{
        parserId: string;
        sampleId: string;
        payload: object;
      }>
    ) {
      state.isSampleTestDataLoading = false;
      state.errorSampleTestData = null;
      state.sampleTestData = action.payload;
    },
    createSampleTestDataFailure(state, action: PayloadAction<string>) {
      state.isSampleTestDataLoading = false;
      state.errorSampleTestData = action.payload;
    },

    createSampleTestDataAsProjectRequest(
      state,
      action: PayloadAction<{ parserId: string; sampleId: string }>
    ) {
      state.isSampleTestDataLoading = true;
      state.errorSampleTestData = null;
    },
    createSampleTestDataAsProjectSuccess(
      state,
      action: PayloadAction<{
        parserId: string;
        sampleId: string;
        payload: object;
      }>
    ) {
      state.isSampleTestDataLoading = false;
      state.errorSampleTestData = null;
      state.sampleTestData = action.payload;
    },
    createSampleTestDataAsProjectFailure(state, action: PayloadAction<string>) {
      state.isSampleTestDataLoading = false;
      state.errorSampleTestData = action.payload;
    },
  },
});

export const parserActions = parserSlice.actions;
export default parserSlice.reducer;
