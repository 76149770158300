import React from "react";
import { Box, Breadcrumbs, Paper, Typography } from "@mui/material";
import { Link } from "react-router-dom";

import { ParserConfigurationSetupForm } from "../components/create-parser-config-form/create-configuration-form";

const CreateParserConfiguration: React.FC = () => {
  return (
    <Paper elevation={3}>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ paddingTop: 5, paddingLeft: 5 }}
      >
        <Link to="/">Home</Link>
        <Link to="/ai/parsers">Parsers</Link>
        <Typography color="textPrimary">New Parser Configuration</Typography>
      </Breadcrumbs>
      <Box display="flex" justifyContent="center" height="100vh">
        <ParserConfigurationSetupForm />
      </Box>
    </Paper>
  );
};

export default CreateParserConfiguration;
