import React, { useEffect, useState } from "react";
import {
  AppBar,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Toolbar,
  Typography,
} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import CheckIcon from "@mui/icons-material/Check";
import CancelIcon from "@mui/icons-material/Close";

import { useDispatch } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";

interface ToolbarSampleActionsProps {
  sampleId: number;
  parserId: string;
  isReady: boolean; // flag to determine if the sample is ready
  onDeleteSample: () => void;
  onMarkSampleReady: () => void;
}

const ToolbarSampleActions: React.FC<ToolbarSampleActionsProps> = ({
  sampleId,
  parserId,
  isReady,
  onDeleteSample,
  onMarkSampleReady,
}) => {
  const [openDeleteConfirm, setOpenDeleteConfirm] = useState(false);
  const [sampleReady, setSampleReady] = useState(isReady);
  const dispatch = useDispatch();

  useEffect(() => {
    setSampleReady(isReady); // Update state when isReady prop changes
  }, [isReady]);

  const handleDeleteClick = () => {
    setOpenDeleteConfirm(true);
  };

  const handleDeleteConfirm = () => {
    onDeleteSample();

    dispatch(
      parserActions.deleteParserSampleRequest({
        parserId,
        sampleId,
      })
    );

    setOpenDeleteConfirm(false);
  };

  const handleToggleMarkReady = () => {
    setSampleReady(!sampleReady);
    dispatch(
      parserActions.markSampleReadyRequest({
        parserId,
        sampleId,
        flag: !sampleReady,
      })
    );
  };

  return (
    <>
      <AppBar position="static" sx={{ bgcolor: "transparent", boxShadow: "none" }}>
        <Toolbar>
          <Button startIcon={<DeleteIcon />} onClick={handleDeleteClick}>
            Delete Sample
          </Button>

          {!sampleReady ? (
            <Button startIcon={<CheckIcon />} onClick={handleToggleMarkReady}>
              Mark Sample Ready
            </Button>
          ) : (
            <Button startIcon={<CancelIcon />} onClick={handleToggleMarkReady}>
              Ignore Sample
            </Button>
          )}
        </Toolbar>
      </AppBar>

      {/* Delete Confirmation Dialog */}
      <Dialog open={openDeleteConfirm} onClose={() => setOpenDeleteConfirm(false)}>
        <DialogTitle>Confirm Deletion</DialogTitle>
        <DialogContent>
          <Typography>Are you sure you want to delete this sample?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenDeleteConfirm(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={handleDeleteConfirm} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ToolbarSampleActions;
