import React, { useEffect } from "react";
import { AppBar, Box, Button, Toolbar, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";
import { selectCurrentParserConfiguration } from "@next/modules/ai/redux/selectors";
import { enqueueSnackbar } from "notistack";
import snackbarUtils from "@next/utils/snackbarUtils";

const ParserDetailsAppBarComponent = ({ parserId }: { parserId: string }) => {
  const dispatch = useDispatch();
  const parserConfiguration = useSelector(selectCurrentParserConfiguration);
  const hasUnpublishedChanges = Boolean(parserConfiguration?.unpublished_data);

  // Fetch current parser configuration when the component mounts
  useEffect(() => {
    dispatch(
      parserActions.fetchParserConfigurationRequest({
        parserId,
        sampleData: null
      })
    );
  }, [dispatch, parserId]);

  const handleCancel = () => {
    dispatch(
      parserActions.updateParserConfigRequest({
        parserId,
        data: {},
        action_type: "cancel"
      })
    );
    dispatch(
      parserActions.fetchParserConfigurationRequest({
        parserId,
        sampleData: null
      })
    );
    snackbarUtils.success("Changes cancelled!");
  };

  const handlePublish = () => {
    dispatch(
      parserActions.updateParserConfigRequest({
        parserId,
        data: {},
        action_type: "publish"
      })
    );
    dispatch(
      parserActions.fetchParserConfigurationRequest({
        parserId,
        sampleData: null
      })
    );
    enqueueSnackbar("Changes published!", { variant: "success" });
  };

  return (
    <>
      {hasUnpublishedChanges && (
        <AppBar position="static" color="default" sx={{ mb: 5 }}>
          <Toolbar>
            <Box
              display="flex"
              justifyContent="flex-end"
              width="100%"
              alignItems="center"
            >
              <Typography variant="body2" sx={{ mr: 2, mt: 1 }}>
                You have unpublished changes in the parser configuration
              </Typography>
              <Button
                variant="contained"
                color="error"
                onClick={handleCancel}
                sx={{ mr: 2 }}
              >
                Cancel Changes
              </Button>
              <Button
                variant="contained"
                color="primary"
                onClick={handlePublish}
              >
                Publish Changes
              </Button>
            </Box>
          </Toolbar>
        </AppBar>
      )}
    </>
  );
};

export default ParserDetailsAppBarComponent;
