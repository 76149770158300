// HighlightableTextField.js
import React, { useRef } from "react";
import { Box, Chip, Typography } from "@mui/material";

export default function HighlightableTextField({
  text,
  highlights,
  onAddHighlight,
  onRemoveHighlight
}) {
  const contentRef = useRef(null);

  const handleTextSelect = () => {
    const selection = window.getSelection();
    const selectedText = selection.toString().trim();

    // Check if there is selected text within the component's bounds
    if (
      selectedText &&
      !highlights.includes(selectedText) &&
      contentRef.current.contains(selection.anchorNode)
    ) {
      onAddHighlight(selectedText);
      selection.removeAllRanges(); // Clear selection after adding
    }
  };

  const renderTextWithHighlights = () => {
    let segments = [];
    let lastIndex = 0;

    // Regular expression to find multiple occurrences of highlights
    const sortedHighlights = [...highlights].sort(
      (a, b) => b.length - a.length
    );
    const regex = new RegExp(`(${sortedHighlights.join("|")})`, "gi");

    text.replace(regex, (match, _, offset) => {
      if (offset > lastIndex) {
        segments.push(
          <span key={`text-${lastIndex}`}>{text.slice(lastIndex, offset)}</span>
        );
      }
      segments.push(
        <Chip
          key={`chip-${offset}-${match}`}
          label={match}
          onDelete={() => onRemoveHighlight(match)}
          color="primary"
          sx={{ margin: "0 4px", display: "inline-flex" }}
        />
      );
      lastIndex = offset + match.length;
    });

    // Add remaining text after last match
    if (lastIndex < text.length) {
      segments.push(
        <span key={`text-${lastIndex}`}>{text.slice(lastIndex)}</span>
      );
    }

    return segments;
  };

  return (
    <Box
      ref={contentRef}
      onMouseUp={handleTextSelect}
      sx={{
        border: "1px solid #ccc",
        padding: "8px",
        borderRadius: "4px",
        whiteSpace: "pre-wrap",
        cursor: "text"
      }}
      contentEditable={false} // Prevent editing but allow text selection
    >
      <Typography component="div">{renderTextWithHighlights()}</Typography>
    </Box>
  );
}
