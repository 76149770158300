import { CustomRoute } from "./types";

import ROOT_ROUTE from "./root";
import AUTH_ROUTES from "./auth";
import PROFILE_ROUTES from "./profile";
import WORKSPACE_ROUTES from "./workspace";
import MARKETPLACE_ROUTES from "./marketplace";
import COMPANY_ROUTES from "./company";
import ERRORS_ROUTES from "./errors";
import PROJECT_ROUTES from "./project";
import PAY_ROUTES from "./pay";
import ORDERS_ROUTES from "./orders";
import AI_ROUTES from "./ai";
import CUSTOM_ROUTES_FOR_COMPANIES from "./custom-routes-for-companies";
import VENDOR_MANAGEMENT_ROUTES from "./vendor-management";

export default [
  ...ROOT_ROUTE,
  ...AUTH_ROUTES,
  ...PROFILE_ROUTES,
  ...WORKSPACE_ROUTES,
  ...MARKETPLACE_ROUTES,
  ...COMPANY_ROUTES,
  ...ERRORS_ROUTES,
  ...PROJECT_ROUTES,
  ...PAY_ROUTES,
  ...AI_ROUTES,
  ...ORDERS_ROUTES,
  ...CUSTOM_ROUTES_FOR_COMPANIES,
  ...VENDOR_MANAGEMENT_ROUTES,
] as CustomRoute[];
