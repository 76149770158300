import React from "react";
import { useHistory } from "react-router-dom";

import {
  AppBar,
  Breadcrumbs,
  Button,
  Paper,
  Toolbar,
  Typography
} from "@mui/material";

import AddIcon from "@mui/icons-material/Add";
import ParsersConfigurationList from "../components/list-parsers-config-grid/list-parsers-config-grid";
import { Link } from "react-router-dom";

const ListParsersConfiguration: React.FC = () => {
  const history = useHistory();

  const handleNewPaserConfigClick = () => {
    history.push(`/ai/parsers/new`);
  };
  return (
    <Paper elevation={3}>
      <Breadcrumbs
        aria-label="breadcrumb"
        sx={{ paddingTop: 5, paddingLeft: 5 }}
      >
        <Link to="/">Home</Link>

        <Typography color="textPrimary"> Parsers</Typography>
      </Breadcrumbs>
      <AppBar
        position="static"
        sx={{ bgcolor: "transparent", boxShadow: "none" }}
      >
        <Toolbar>
          <Button
            color="inherit"
            sx={{ ml: "auto", color: "primary.main" }}
            startIcon={<AddIcon />}
            onClick={() => handleNewPaserConfigClick()}
          >
            New Parser Configuration
          </Button>
        </Toolbar>
      </AppBar>

      <ParsersConfigurationList />
    </Paper>
  );
};

export default ListParsersConfiguration;
