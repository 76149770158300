import { axiosApi } from "@next/api";
import { backendUrl } from "urls";
import { TestRegexPayload } from "../redux";

const getSuppliers = () => {
  return axiosApi.get(`${backendUrl.aiParser}/buyerSuppliers`);
};

const createParserConfiguration = (formData: FormData) => {
  return axiosApi.post(`${backendUrl.aiParser}/parsersConfiguration`, formData, {
    headers: {
      "Content-Type": "multipart/form-data",
    },
  });
};

const getParserSamples = (parserId: string) => {
  return axiosApi.get(`${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples`);
};

export const uploadSample = (parserId: string, formData: FormData) => {
  return axiosApi.post(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples`,
    formData,
    {
      headers: {
        "Content-Type": "multipart/form-data",
      },
    }
  );
};

export const getConfigurations = () => {
  return axiosApi.get(`${backendUrl.aiParser}/parsersConfiguration`);
};

// Update field in backend
const updateSampleField = (parserId: string, sampleId: number, fieldKey: string, data: object) => {
  return axiosApi.patch(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}`,
    {
      extracted_fields: { [fieldKey]: data },
    }
  );
};

export const updateParserConfig = (parserId: string, action_type: string, data: object) => {
  return axiosApi.patch(`${backendUrl.aiParser}/parsersConfiguration/${parserId}`, {
    data,
    action: action_type,
  });
};

const getParserConfiguration = (parserId: string) =>
  axiosApi.get(`${backendUrl.aiParser}/parsersConfiguration/${parserId}`);

const testRegex = (payload: TestRegexPayload) =>
  axiosApi.post(`${backendUrl.aiParser}/parsers/testParserRegex`, payload);

export const toggleSampleReadyFlag = (parserId: string, sampleId: number, flag: boolean) => {
  return axiosApi.patch(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}`,
    {
      is_ready: flag,
    }
  );
};

export const deleteSample = (parserId: string, sampleId: number) => {
  return axiosApi.delete(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}`
  );
};

export const fetchSampleData = (parserId: string, sampleId: string) => {
  return axiosApi.get<SampleData>(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}`
  );
};

const fetchSampleResult = (parserId: string, sampleId: string) => {
  return axiosApi.get(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}/runSample`
  );
};

const fetchSampleTestData = (parserId: string, sampleId: string) => {
  return axiosApi.get(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}/testData`
  );
};

const updateSampleTestData = (parserId: string, sampleId: string, payload: object) => {
  return axiosApi.patch(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}/testData`,
    payload
  );
};

const createSampleTestData = (parserId: string, sampleId: string) => {
  return axiosApi.post(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}/testData`,
    {}
  );
};

const createSampleDataAsProject = (parserId: string, sampleId: string) => {
  return axiosApi.post(
    `${backendUrl.aiParser}/parsersConfiguration/${parserId}/samples/${sampleId}/testDataAsProject`,
    {}
  );
};

const exportParserAsJson = async (parserId: string, supplierName: string) => {
  try {
    const response = await axiosApi.get(
      `${backendUrl.aiParser}/parsersConfiguration/export/${parserId}`,
      {
        responseType: "blob", // Important for handling file download
      }
    );

    // Create a URL for the blob object
    debugger;
    const blobUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement("a");
    link.href = blobUrl;
    link.setAttribute("download", `parser_${supplierName}_configurations.json`);
    document.body.appendChild(link);
    link.click();

    // Cleanup
    link.parentNode.removeChild(link);
    window.URL.revokeObjectURL(blobUrl);
  } catch (error) {
    console.error("Error exporting parser configuration:", error);
  }
};
export const aiParserService = {
  getConfigurations,
  getSuppliers,
  createParserConfiguration,
  getParserSamples,
  uploadSample,
  deleteSample,
  getParserConfiguration,
  updateSampleField,
  updateParserConfig,
  testRegex,
  toggleSampleReadyFlag,
  fetchSampleResult,
  fetchSampleData,
  fetchSampleTestData,
  updateSampleTestData,
  createSampleTestData,
  createSampleDataAsProject,
  exportParserAsJson,
};
