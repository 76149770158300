import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Dialog,
  IconButton,
  TextField,
  Tooltip,
  Typography
} from "@mui/material";
import RawTextDialog from "./raw-text-dialog";
import { DataGridProV5 } from "@next/components/data-grid-pro-v5";
import TextSelectIcon from "@mui/icons-material/AutoFixHigh";
import RegexIcon from "@mui/icons-material/Code";
import RegexDialog from "./regex-config-dialog";

import { useDispatch, useSelector } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";
import {
  selectConfigurations,
  selectFields,
  selectFieldsError,
  selectFieldsLoading
} from "@next/modules/ai/redux/selectors";

const FieldsExtractionGrid: React.FC<FieldsExtractionGridProps> = ({
  sampleData,
  parserId
}) => {
  const dispatch = useDispatch();

  const fields = useSelector(selectFields) || [];
  const configuration = useSelector(selectConfigurations);
  const loading = useSelector(selectFieldsLoading);
  const error = useSelector(selectFieldsError);

  const [selectedFieldId, setSelectedFieldId] = useState<number | null>(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [regexDialogOpen, setRegexDialogOpen] = useState(false);
  const [currentRegexField, setCurrentRegexField] = useState<Field | null>(
    null
  );
  const [currentParserConfiguration, setCurrentParserConfiguration] =
    useState(null);

  useEffect(() => {
    if (configuration) {
      setCurrentParserConfiguration(
        configuration.filter((item) => item.id == parserId)[0]
      );
    }
  }, [configuration]);

  useEffect(() => {
    dispatch(parserActions.fetchConfigurationsRequest());
    dispatch(
      parserActions.fetchParserConfigurationRequest({ parserId, sampleData })
    );
  }, [dispatch, parserId, sampleData]);

  const handleOpenRegexDialog = (id: number) => {
    const selectedField = fields.find((field) => field.id === id);
    if (selectedField) {
      setCurrentRegexField(selectedField);
      setSelectedFieldId(id);
      setRegexDialogOpen(true);
    }
  };

  const handleCloseRegexDialog = () => {
    setRegexDialogOpen(false);
    setCurrentRegexField(null);
    setSelectedFieldId(null);
  };

  const handleOpenDialog = (id: number) => {
    setSelectedFieldId(id);
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
    setSelectedFieldId(null);
  };

  const handleSelectText = (text: string) => {
    if (selectedFieldId !== null) {
      const selectedField = fields.find(
        (field) => field.id === selectedFieldId
      );
      if (selectedField) {
        dispatch(
          parserActions.updateSampleField({
            parserId: parserId,
            sampleId: sampleData.id,
            fieldId: selectedField.id,
            fieldKey: selectedField.key,
            data: { selectedText: text }
          })
        );
      }
      handleCloseDialog();
    }
  };

  const getRawTextFromFieldId = (
    selectedFieldId: number,
    sampleData: SampleData
  ): string | null => {
    const selectedField = fields.find((field) => field.id === selectedFieldId);

    if (!selectedField) {
      return sampleData.probe_text;
    }

    const rawTextFieldId = selectedField.rawTextFromFieldId;

    if (rawTextFieldId === null) {
      return sampleData.probe_text;
    }

    const rawTextField = fields.find((field) => field.id === rawTextFieldId);

    if (!rawTextField) {
      return sampleData.probe_text;
    }

    if (!rawTextField.selectedText) {
      return sampleData.probe_text;
    }

    return rawTextField.selectedText;
  };

  const columns = [
    { field: "name", headerName: "Field Name", width: 100 },
    {
      field: "selectedText",
      headerName: "Selected Text",
      flex: 1,
      renderCell: (params) => {
        const text = params.value || "";
        const truncatedText =
          text.length > 100 ? `${text.substring(0, 100)}...` : text;
        return (
          <Tooltip title={text} placement="top-start">
            <div
              style={{
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis"
              }}
            >
              {truncatedText}
            </div>
          </Tooltip>
        );
      }
    },
    {
      field: "expectedData",
      headerName: "Expected Result",
      flex: 2,
      renderCell: (params) => {
        const { expectedData } = params.row;
        return (
          <Autocomplete
            style={{
              width: "100%",
              padding: 20,
              border: 0
            }}
            multiple
            options={[]}
            freeSolo
            filterSelectedOptions={false}
            value={expectedData || []}
            isOptionEqualToValue={(option, value) => false}
            onChange={(event, newValue) =>
              dispatch(
                parserActions.updateSampleField({
                  parserId: parserId,
                  sampleId: sampleData.id,
                  fieldId: params.row.id,
                  fieldKey: params.row.key,
                  data: { expectedData: newValue }
                })
              )
            }
            getOptionKey={(option) => Math.random()}
            renderInput={(params) => (
              <TextField
                {...params}
                variant="outlined"
                style={{ width: "100%", height: "100%", border: 0 }}
              />
            )}
          />
        );
      }
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      renderCell: (params) => (
        <Box display="flex" gap={1}>
          <IconButton
            color="primary"
            onClick={() => handleOpenDialog(params.row.id)}
          >
            <TextSelectIcon />
          </IconButton>
          <IconButton
            color="primary"
            onClick={() => handleOpenRegexDialog(params.row.id)}
          >
            <RegexIcon />
          </IconButton>
        </Box>
      )
    }
  ];

  return (
    <Box sx={{ padding: "16px", backgroundColor: "#f4f4f4" }}>
      <Box sx={{ marginBottom: "16px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "4px" }}
        >
          Extract Fields
        </Typography>
      </Box>

      {loading ? (
        <Typography>Loading...</Typography>
      ) : error ? (
        <Typography>Error: {error}</Typography>
      ) : (
        <Box
          sx={{
            backgroundColor: "#fff",
            borderRadius: "8px",
            padding: "8px"
          }}
        >
          <DataGridProV5
            rows={fields}
            columns={columns}
            autoHeight
            disableColumnMenu
            hideFooter
            disableSelectionOnClick
            getRowId={(row) => row.id}
            getRowHeight={() => "auto"}
          />
        </Box>
      )}

      <Dialog open={dialogOpen} onClose={handleCloseDialog}>
        {selectedFieldId !== null && (
          <RawTextDialog
            pdfUrl={sampleData.pdf_file}
            rawText={getRawTextFromFieldId(selectedFieldId, sampleData)}
            onSelect={handleSelectText}
            onClose={handleCloseDialog}
          />
        )}
      </Dialog>

      {selectedFieldId && (
        <Dialog open={regexDialogOpen} onClose={handleCloseRegexDialog}>
          <RegexDialog
            field={fields.find((field) => field.id === selectedFieldId)}
            parserId={parserId}
            sampleId={sampleData.id}
            selectedText={currentRegexField?.selectedText || ""}
            currentRegex={currentRegexField?.regex || ""}
            lineDetectorRegex={
              currentParserConfiguration.data?.lineDetector?.regex?.value
            }
            onClose={handleCloseRegexDialog}
          />
        </Dialog>
      )}
    </Box>
  );
};

export default FieldsExtractionGrid;
