import { history } from "helpers/history";
import { call, put, takeEvery, takeLatest } from "redux-saga/effects";
import { PayloadAction } from "@reduxjs/toolkit";
import { parserActions } from "./slices";
import { safeCall } from "@next/redux/safeCall";
import { aiParserService, fetchSampleData } from "@next/modules/ai/services/aiParserService";
import { Configuration, MarkSampleReadyPayload, TestRegexPayload } from "./types";

function* handleFetchSamples(action: PayloadAction<string>) {
  try {
    const { response, error } = yield safeCall(aiParserService.getParserSamples, action.payload);

    if (error) {
      yield put(parserActions.fetchSamplesFailure(error));
    } else {
      yield put(parserActions.fetchSamplesSuccess(response.data));
    }
  } catch (error: any) {
    yield put(parserActions.fetchSamplesFailure(error.message || "Failed to fetch samples"));
  }
}

function* handleCreateConfiguration(action) {
  try {
    const response = yield call(aiParserService.createParserConfiguration, action.payload);
    yield put(parserActions.createConfigurationSuccess(response.data));
    yield put(parserActions.fetchConfigurationsRequest());
    history.push(`/ai/parsers/${response.data.supplier_name}:${response.data.id}`);
  } catch (error) {
    yield put(
      parserActions.createConfigurationFailure(error.message || "Failed to create configuration")
    );
  }
}

function* handleFetchSuppliers() {
  try {
    const response = yield call(aiParserService.getSuppliers);
    yield put(parserActions.fetchSuppliersSuccess(response.data));
  } catch (error: any) {
    yield put(parserActions.fetchSuppliersFailure(error.message || "Failed to fetch suppliers"));
  }
}

function* handleUploadSample(action: PayloadAction<{ parserId: string; formData: FormData }>) {
  try {
    yield call(aiParserService.uploadSample, action.payload.parserId, action.payload.formData);

    // Fetch the updated list of samples after successful upload
    const response = yield call(aiParserService.getParserSamples, action.payload.parserId);
    yield put(parserActions.uploadSampleSuccess());

    yield put(parserActions.fetchSamplesSuccess(response.data));
  } catch (error: any) {
    yield put(parserActions.uploadSampleFailure(error.message || "Failed to upload sample"));
  }
}

function* handleDeleteSample(action: PayloadAction<{ parserId: string; sampleId: number }>) {
  try {
    yield call(aiParserService.deleteSample, action.payload.parserId, action.payload.sampleId);

    yield call(aiParserService.getParserSamples, action.payload.parserId);
    yield put(parserActions.deleteParserSampleSuccess());
    history.push(`/ai/parsers/--:${action.payload.parserId}`);
  } catch (error: any) {
    yield put(parserActions.deleteParserSampleError(error.message || "Failed to delete sample"));
  }
}

function* handleFetchConfigurations() {
  try {
    const response = yield call(aiParserService.getConfigurations);
    yield put(parserActions.fetchConfigurationsSuccess(response.data));
  } catch (error: any) {
    yield put(
      parserActions.fetchConfigurationsFailure(error.message || "Failed to fetch configurations")
    );
  }
}

function* handleFetchParserConfiguration(
  action: PayloadAction<{ parserId: string; sampleData: any }>
) {
  const { parserId, sampleData } = action.payload;

  try {
    const response = yield call(aiParserService.getParserConfiguration, parserId);
    const globalConfig = response.data.data;

    // Merge global config and sample data to create fields
    const fields = [
      {
        id: 1,
        name: "Fixed Fee",
        key: "fixedFee",
        selectedText: sampleData?.extracted_fields?.fixedFee?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.fixedFee?.expectedData || [],
        regex: globalConfig.fixedFee?.regex || "",
        rawTextFromFieldId: null,
      },
      {
        id: 2,
        name: "Subtotal",
        key: "subTotal",
        selectedText: sampleData?.extracted_fields?.subTotal?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.subTotal?.expectedData || [],
        regex: globalConfig.subTotal?.regex || "",
        rawTextFromFieldId: null,
      },
      {
        id: 3,
        name: "Lead Time",
        key: "leadTime",
        selectedText: sampleData?.extracted_fields?.leadTime?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.leadTime?.expectedData || [],
        regex: globalConfig.leadTime?.regex || "",
        rawTextFromFieldId: null,
      },
      {
        id: 4,
        name: "Part Delimiter",
        key: "partDelimiter",
        selectedText: sampleData?.extracted_fields?.partDelimiter?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.partDelimiter?.expectedData || [],
        regex: globalConfig.partDelimiter?.regex || "",
        rawTextFromFieldId: null,
      },
      {
        id: 5,
        name: "Line Detector",
        key: "lineDetector",
        selectedText: sampleData?.extracted_fields?.lineDetector?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.lineDetector?.expectedData || [],
        regex: globalConfig.lineDetector?.regex || "",
        rawTextFromFieldId: null,
      },
      {
        id: 6,
        name: "Item Name",
        key: "itemName",
        selectedText: sampleData?.extracted_fields?.itemName?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.itemName?.expectedData || [],
        regex: globalConfig.itemName?.regex || "",
        rawTextFromFieldId: 2,
      },
      {
        id: 7,
        name: "Item Description",
        key: "itemDescription",
        selectedText: sampleData?.extracted_fields?.itemDescription?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.itemDescription?.expectedData || [],
        regex: globalConfig.itemDescription?.regex || "",
        rawTextFromFieldId: 2,
      },
      {
        id: 8,
        name: "Quantity",
        key: "quantity",
        selectedText: sampleData?.extracted_fields?.quantity?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.quantity?.expectedData || [],
        regex: globalConfig.quantity?.regex || "",
        rawTextFromFieldId: 2,
      },
      {
        id: 9,
        name: "Unit Price",
        key: "unitPrice",
        selectedText: sampleData?.extracted_fields?.unitPrice?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.unitPrice?.expectedData || [],
        regex: globalConfig.unitPrice?.regex || "",
        rawTextFromFieldId: 2,
      },
      {
        id: 10,
        name: "Line Number",
        key: "lineNumber",
        selectedText: sampleData?.extracted_fields?.lineNumber?.selectedText || "",
        expectedData: sampleData?.extracted_fields?.lineNumber?.expectedData || [],
        regex: globalConfig.lineNumber?.regex || "",
        rawTextFromFieldId: 2,
      },
    ];

    yield put(
      parserActions.fetchParserConfigurationSuccess({
        fields: fields,
        parserConfiguration: response.data,
      })
    );
  } catch (error: any) {
    yield put(
      parserActions.fetchParserConfigurationFailure(error.message || "Failed to fetch fields")
    );
  }
}

// Update sample
function* handleUpdateFieldSample(
  action: PayloadAction<{
    parserId: string;
    sampleId: number;
    fieldId: number;
    fieldKey: string;
    data: object;
  }>
) {
  const { parserId, sampleId, fieldId, fieldKey, data } = action.payload;
  try {
    yield call(aiParserService.updateSampleField, parserId, sampleId, fieldKey, data);
  } catch (error: any) {
    // Dispatch failure action
    // Sample Field failure
    // yield put(
    //   parserActions.updateFieldRegexFailure(
    //     error.message || "Failed to update field regex",
    //   ),
    // );
  }
}

// Update field regex in backend
function* handleUpdateParserConfig(
  action: PayloadAction<{
    parserId: string;
    data: object;
    action_type: "update" | "cancel" | "publish";
  }>
) {
  const { parserId, data, action_type } = action.payload;
  try {
    // API call to update the field's regex
    const response = yield call(aiParserService.updateParserConfig, parserId, action_type, data);

    const configuration: Configuration = response.data;
    if (action_type == "update") {
      const fieldId = Object.keys(action.payload.data)[0];
      const regex = Object.values(action.payload.data)[0]?.regex;
      yield put(
        parserActions.updateParserConfigSuccess({
          fieldId: fieldId,
          regex: regex,
          configuration,
        })
      );
    } else {
      yield put(
        parserActions.updateParserConfigSuccess({
          fieldId: null,
          regex: null,
          configuration,
        })
      );
    }
  } catch (error: any) {
    // Dispatch failure action
    yield put(
      parserActions.updateParserConfigFailure(error.message || "Failed to update field regex")
    );
  }
}

// Test Regex saga
function* handleTestRegex(action: PayloadAction<TestRegexPayload>) {
  try {
    const response = yield call(aiParserService.testRegex, action.payload);
    yield put(parserActions.testRegexSuccess(response.data));
  } catch (error: any) {
    yield put(parserActions.testRegexFailure(error.message || "Failed to test regex"));
  }
}

function* handleMarkSampleReady(action: PayloadAction<MarkSampleReadyPayload>) {
  const { parserId, sampleId, flag } = action.payload;

  try {
    // Call the API to mark the sample as ready
    yield call(aiParserService.toggleSampleReadyFlag, parserId, sampleId, flag);

    // Dispatch the success action
    yield put(parserActions.markSampleReadySuccess());

    // Optionally, you can trigger additional actions or logic here
  } catch (error: any) {
    // Dispatch the failure action
    yield put(
      parserActions.markSampleReadyFailure(error.message || "Error marking sample as ready")
    );
  }
}

interface FetchSampleResultPayload {
  parserId: string;
  sampleId: string;
}

// Saga function to handle fetching the sample result
function* handleFetchSampleResult(action: PayloadAction<{ parserId: string; sampleId: string }>) {
  const { parserId, sampleId } = action.payload;

  try {
    // Call the API to fetch the sample result
    const response = yield call(aiParserService.fetchSampleResult, parserId, sampleId);
    const sampleResult = response.data.sample_execution_result;
    const failedSamples = response.data.failed_samples;
    const lastRunDate = new Date().toLocaleString();

    yield put(
      parserActions.fetchSampleResultSuccess({
        sampleId,
        sampleResult,
        failedSamples,
        lastRunDate,
      })
    );
  } catch (error: any) {
    yield put(
      parserActions.fetchSampleResultFailure({
        sampleId,
        error: error.message || "Failed to fetch sample results",
      })
    );
  }
}

function* handleFetchSampleData(action: PayloadAction<{ parserId: string; sampleId: string }>) {
  const { parserId, sampleId } = action.payload;
  try {
    const response = yield call(fetchSampleData, parserId, sampleId);
    yield put(parserActions.fetchSampleDataSuccess(response.data));
  } catch (err: any) {
    yield put(parserActions.fetchSampleDataFailure(err.message || "Failed to load sample data."));
  }
}

function* handleFetchSampleTestData(action: PayloadAction<{ parserId: string; sampleId: string }>) {
  const { parserId, sampleId } = action.payload;

  try {
    // Call the API to fetch the sample result
    const response = yield call(aiParserService.fetchSampleTestData, parserId, sampleId);

    yield put(parserActions.fetchSampleTestDataSuccess(response.data));
  } catch (error: any) {
    yield put(parserActions.fetchSampleTestDataFailure("Error while loading sample data"));
  }
}

function* handleUpdateSampleTestData(
  action: PayloadAction<{ parserId: string; sampleId: string; payload: object }>
) {
  const { parserId, sampleId, payload } = action.payload;

  try {
    yield call(aiParserService.updateSampleTestData, parserId, sampleId, payload);
    yield put(parserActions.updateSampleTestDataSuccess());
  } catch (error: any) {
    yield put(
      parserActions.updateSampleTestDataFailure(error.message || "Error updating sample test data")
    );
  }
}

function* handleCreateSampleTestData(
  action: PayloadAction<{ parserId: string; sampleId: string }>
) {
  const { parserId, sampleId } = action.payload;

  try {
    const response = yield call(aiParserService.createSampleTestData, parserId, sampleId);
    yield put(parserActions.createSampleTestDataSuccess(response.data));
  } catch (error: any) {
    yield put(
      parserActions.createSampleTestDataFailure(error.message || "Error creating sample test data")
    );
  }
}

function* handleCreateSampleDataAsProject(
  action: PayloadAction<{ parserId: string; sampleId: string }>
) {
  const { parserId, sampleId } = action.payload;

  try {
    const response = yield call(aiParserService.createSampleDataAsProject, parserId, sampleId);
    yield put(parserActions.createSampleTestDataAsProjectSuccess(response.data));
  } catch (error: any) {
    yield put(
      parserActions.createSampleTestDataAsProjectFailure(
        error.message || "Error creating sample test data"
      )
    );
  }
}
export function* parserSaga() {
  yield takeLatest(parserActions.uploadSampleRequest.type, handleUploadSample);
  yield takeLatest(parserActions.deleteParserSampleRequest.type, handleDeleteSample);

  yield takeLatest(parserActions.fetchConfigurationsRequest.type, handleFetchConfigurations);

  yield takeLatest(parserActions.fetchSuppliersRequest.type, handleFetchSuppliers);

  yield takeLatest(parserActions.createConfigurationRequest.type, handleCreateConfiguration);
  yield takeLatest(parserActions.updateParserConfigRequest.type, handleUpdateParserConfig);
  yield takeLatest(parserActions.fetchSamplesRequest.type, handleFetchSamples);
  yield takeLatest(
    parserActions.fetchParserConfigurationRequest.type,
    handleFetchParserConfiguration
  );
  yield takeLatest(parserActions.testRegexRequest.type, handleTestRegex);
  yield takeLatest(parserActions.updateSampleField.type, handleUpdateFieldSample);

  yield takeLatest(parserActions.markSampleReadyRequest.type, handleMarkSampleReady);
  yield takeEvery("parser/fetchSampleResultRequest", handleFetchSampleResult);

  yield takeLatest(parserActions.fetchSampleDataRequest.type, handleFetchSampleData);

  yield takeLatest(parserActions.fetchSampleTestDataRequest, handleFetchSampleTestData);

  yield takeLatest(parserActions.updateSampleTestDataRequest.type, handleUpdateSampleTestData);
  yield takeLatest(parserActions.createSampleTestDataRequest.type, handleCreateSampleTestData);

  yield takeLatest(
    parserActions.createSampleTestDataAsProjectRequest.type,
    handleCreateSampleDataAsProject
  );
}
