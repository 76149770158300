import React from "react";
// Urls
// Layout
import AppLayout from "layout/AppLayout";
// Components
import CreateParserConfiguration from "@next/modules/ai/pages/create-parsers-configuration";
// Types
import { CustomRoute } from "./types";
import ListParsersConfiguration from "@next/modules/ai/pages/list-parsers-configuration";
import ParserConfigurationSetup from "@next/modules/ai/pages/parser-configuration-setup";
import ParsersConfigurationDetails from "@next/modules/ai/pages/list-parsers-configuration-details";
import ParserFieldConfiguration from "@next/modules/ai/pages/parser-field-config";

const AI_ROUTES: CustomRoute[] = [
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/new`,
    key: "axya-ai-parser-new",
    exact: true,
    component: () => <CreateParserConfiguration />
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers`,
    key: "axya-ai-list-parsers",
    exact: true,
    component: () => <ListParsersConfiguration />
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/:id`,
    key: "axya-ai-parser-config-details",
    exact: true,
    component: () => <ParsersConfigurationDetails />
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/:parserId/samples/:sampleId`,
    key: "axya-ai-list-parsers",
    exact: true,
    component: () => <ParserConfigurationSetup />
  },
  {
    layout: (props) => <AppLayout {...props} />,
    path: `/ai/parsers/:parserId/fields`,
    key: "axya-ai-parser-fields-config",
    exact: true,
    component: () => <ParserFieldConfiguration />
  }
];

export default AI_ROUTES;
