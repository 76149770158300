import React, { useEffect } from "react";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Chip,
  CircularProgress,
  IconButton,
  Typography
} from "@mui/material";

import RunSampleIcon from "@mui/icons-material/PlayArrow";

import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import CancelIcon from "@mui/icons-material/Cancel";
import { useDispatch, useSelector } from "react-redux";
import { parserActions } from "@next/modules/ai/redux/slices";

import {
  selectSampleDataById,
  selectSampleError,
  selectSampleLoading
} from "@next/modules/ai/redux/selectors";

import { RootState } from "store";

const SampleRunnerResult = ({ parserId, sampleId }) => {
  const dispatch = useDispatch();

  const sampleData = useSelector((state: RootState) =>
    selectSampleDataById(state, sampleId)
  );
  const loading = useSelector((state: RootState) =>
    selectSampleLoading(state, sampleId)
  );
  const error = useSelector((state: RootState) =>
    selectSampleError(state, sampleId)
  );
  const { sampleResult, failedSamples, lastRunDate } = sampleData || {};

  useEffect(() => {
    dispatch(parserActions.fetchSampleResultRequest({ parserId, sampleId }));
  }, [dispatch, parserId, sampleId]);

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }
  if (error) {
    return <Typography color="error">Error: {error}</Typography>;
  }

  if (!sampleResult) {
    return "Error loading samples";
  }

  return (
    <Box sx={{ padding: "16px", backgroundColor: "#f4f4f4" }}>
      {/* Panel Header */}
      <Box sx={{ marginBottom: "16px" }}>
        <Typography
          variant="h6"
          sx={{ fontWeight: "bold", marginBottom: "4px", marginRight: "4px" }}
        >
          Sample Execution
          <IconButton
            color="primary"
            onClick={() =>
              dispatch(
                parserActions.fetchSampleResultRequest({ parserId, sampleId })
              )
            }
          >
            <RunSampleIcon /> Run Sample
          </IconButton>
          <Chip
            style={{ marginLeft: "4px" }}
            label={sampleResult.is_passed ? "Success" : "Failed"}
            color={sampleResult.is_passed ? "success" : "error"}
            size="small"
          />
        </Typography>
        <Typography variant="body2" color="textSecondary">
          Last run at {lastRunDate}
        </Typography>
      </Box>

      {/* Accordion Panel */}
      <Box
        sx={{ backgroundColor: "#fff", borderRadius: "8px", padding: "8px" }}
      >
        {sampleResult &&
          sampleResult.field_results?.map((result, index) => (
            <Accordion
              key={index}
              disableGutters
              elevation={0}
              square
              sx={{
                "&:not(:last-child)": {
                  borderBottom: "1px solid #ddd" // Remove visible border between accordions
                },
                "&:before": {
                  display: "none" // Remove the default accordion divider
                }
              }}
            >
              <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                sx={{
                  backgroundColor: "transparent",
                  flexDirection: "row-reverse", // Brings the expand icon to the left
                  minHeight: "40px", // Compact height
                  "& .MuiAccordionSummary-content": {
                    margin: 0 // Remove extra margin
                  }
                }}
              >
                <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
                  {result.is_passed ? (
                    <CheckCircleIcon
                      color="success"
                      sx={{ marginRight: "8px" }}
                    />
                  ) : (
                    <CancelIcon color="error" sx={{ marginRight: "8px" }} />
                  )}
                  <Typography variant="body1">{result.title}</Typography>
                </Box>
              </AccordionSummary>
              <AccordionDetails sx={{ padding: "8px 16px" }}>
                {result.type === "field_extraction" ? (
                  <>
                    <Typography variant="body2">
                      <strong>Extracted Value:</strong>{" "}
                      {Array.isArray(result.result.extracted_values)
                        ? result.result.extracted_values.join(", ")
                        : ""}
                    </Typography>
                    <Typography variant="body2">
                      <strong>Expected Value:</strong>{" "}
                      {Array.isArray(result.result.expected_values)
                        ? result.result.expected_values.join(", ")
                        : ""}
                    </Typography>
                    {result.reason && (
                      <Typography variant="body2" color="error">
                        <strong>Reason:</strong> {result.reason}
                      </Typography>
                    )}
                  </>
                ) : (
                  <Typography variant="body2" color="error">
                    <strong>Reason:</strong> {result.reason}
                  </Typography>
                )}
              </AccordionDetails>
            </Accordion>
          ))}

        <Accordion
          disableGutters
          elevation={0}
          square
          sx={{
            "&:not(:last-child)": {
              borderBottom: "1px solid #ddd"
            },
            "&:before": {
              display: "none"
            }
          }}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            sx={{
              backgroundColor: "transparent",
              flexDirection: "row-reverse",
              minHeight: "40px",
              "& .MuiAccordionSummary-content": {
                margin: 0
              }
            }}
          >
            <Box display="flex" alignItems="center" sx={{ flexGrow: 1 }}>
              {failedSamples.length == 0 ? (
                <CheckCircleIcon color="success" sx={{ marginRight: "8px" }} />
              ) : (
                <CancelIcon color="error" sx={{ marginRight: "8px" }} />
              )}
              <Typography variant="body1">Executing other samples</Typography>
            </Box>
          </AccordionSummary>
          <AccordionDetails sx={{ padding: "8px 16px" }}>
            {failedSamples.length > 0 ? (
              <>
                <Typography variant="body2">
                  <strong>Failed Samples</strong>
                  {failedSamples.join(",")}
                </Typography>
              </>
            ) : (
              <Typography variant="body2" color="success">
                All samples are passing.
              </Typography>
            )}
          </AccordionDetails>
        </Accordion>
      </Box>
    </Box>
  );
};

export default SampleRunnerResult;
